import {mapActions, mapGetters, mapMutations} from "vuex";

const _ = require('lodash');

export default {
    data(){
      return{
          orderList: null,
      }
    },
    computed:{
      ...mapGetters({
          order:'orders/order',
          ordersPhoneList: 'orders/ordersPhoneList',
          orderStatusList:'orders/orderStatusList',
      })
    },
    watch:{
        ordersPhoneList(){
            this.orderList = _.clone(this.ordersPhoneList.data);
        },
        orderList(e){
            e.map(item=>{
                let price = 0;
                item.order_products.map(productItem => {
                    price += productItem.price * productItem.quantity;
                })
                let orderStatus = this.orderStatusList.data.filter(itemStatus => itemStatus.id === item.order_status_id);
                orderStatus = orderStatus[0];
                item.order_status = orderStatus
                item.price = price
            })
        },

    },
    created() {
        if(this.ordersPhoneList){
         this.orderList = _.clone(this.ordersPhoneList.data);
        }
    },
    methods:{
        showOtherUserOrder(item){
            this.showUserOrder(this.order)
            this.showOrder(item.id).then(()=>{
                this.closePopup();
            })
        },
        closePopup(){
            this.$emit('closePopup');
        },
        ...mapActions({
            showOrder:'orders/showOrder'
        }),
        ...mapMutations({
            showUserOrder: 'orders/showUserOrder'
        })
    },
    destroyed() {
        this.orderList = null
    }
}
